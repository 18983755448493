import { Avatar, Button, Typography } from "antd";
import { SortingDate, SortingText } from "../../../utils/sortingData";
import { ProFormSelect } from "@ant-design/pro-components";
import {
  DeleteFilled,
  FileTextFilled,
  EditFilled,
  CheckCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { EVENT_CATAGORIES } from "../../../utils/constant";

const { Text } = Typography;

export const useEventTableColumns = (subCategories, showModal = () => {}) => {
  const navigate = useNavigate();

  return [
    {
      key: "ticketTypeImage",
      title: "Ticket type image",
      search: false,
      render: (_, record) => {
        return <Avatar src={record?.ticketTypeImage} />;
      },
    },
    {
      key: "eventDetailImage",
      title: "Event detail image",
      search: false,
      render: (_, record) => {
        return <Avatar src={record?.eventDetailImage} />;
      },
    },
    {
      key: "image",
      title: "Title image",
      search: false,
      render: (_, record) => {
        return <Avatar src={record?.image} />;
      },
    },
    {
      key: "nameEn",
      title: "English Name",
      dataIndex: "nameEn",
      search: false,
      width: 300,
      sorter: (current, next) => SortingText(current.nameEn, next.nameEn),
    },
    {
      key: "nameAr",
      title: "Arabic Name",
      dataIndex: "nameAr",
      search: false,
      width: 300,
      sorter: (current, next) => SortingText(current.nameAr, next.nameAr),
    },
    {
      key: "subCategoryId",
      title: "Sub category",
      hideInTable: true,
      renderFormItem: (
        _,
        { type, defaultRender, formItemProps, fieldProps, ...rest },
        form
      ) => {
        if (type === "form") {
          return null;
        }
        return (
          <ProFormSelect
            {...fieldProps}
            width={"lg"}
            placeholder="Select sub category"
            options={subCategories}
            allowClear
          />
        );
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      search: false,
      // sorter: (current, next) => SortingText(current.status, next.status),
    },
    {
      key: "locationEn",
      title: "English Location",
      dataIndex: "locationEn",
      search: false,
      sorter: (current, next) =>
        SortingText(current.locationEn, next.locationEn),
    },
    {
      key: "locationAr",
      title: "Arabic Location",
      dataIndex: "locationAr",
      search: false,
      sorter: (current, next) =>
        SortingText(current.locationAr, next.locationAr),
    },
    {
      key: "cityEn",
      title: "English City",
      dataIndex: "cityEn",
      search: false,
      sorter: (current, next) => SortingText(current.cityEn, next.cityEn),
    },
    {
      key: "cityAr",
      title: "Arabic City",
      dataIndex: "cityAr",
      search: false,
      sorter: (current, next) => SortingText(current.cityAr, next.cityAr),
    },
    {
      key: "country",
      title: "Country",
      dataIndex: "country",
      search: false,
      sorter: (current, next) => SortingText(current.country, next.country),
    },
    {
      key: "category",
      title: "Category",
      dataIndex: "category",
      sorter: (current, next) => SortingText(current.category, next.category),
      valueEnum: EVENT_CATAGORIES,
    },
    {
      key: "isFeatured",
      title: "Is featured",
      dataIndex: "isFeatured",
      width: 100,
      valueEnum: {
        TRUE: {
          text: "TRUE",
        },
        FALSE: {
          text: "FALSE",
        },
      },
      render: (_, record) => {
        if (record?.isFeatured) {
          return (
            <CheckCircleOutlined
              style={{
                color: "green",
              }}
            />
          );
        }

        return (
          <MinusCircleOutlined
            style={{
              color: "red",
            }}
          />
        );
      },
    },
    {
      key: "isHasRoom",
      title: "Is has room",
      dataIndex: "isHasRoom",
      width: 100,
      search: false,
      render: (_, record) => {
        if (record?.waitingRoom) {
          return (
            <CheckCircleOutlined
              style={{
                color: "green",
              }}
            />
          );
        }

        return (
          <MinusCircleOutlined
            style={{
              color: "red",
            }}
          />
        );
      },
    },
    {
      key: "startTime",
      title: "Start Time",
      dataIndex: "startTime",
      valueType: "dateTimeRange",
      search: false,
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) => SortingDate(current.startTime, next.startTime),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.startTime)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      search: false,
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) => SortingDate(current.createdAt, next.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Updated At",
      dataIndex: "updatedAt",
      hideInTable: true,
      search: false,
      sorter: (current, next) => SortingDate(current.updatedAt, next.updatedAt),
      render: (_, record) => {
        return <Text>{dayjs(record.updatedAt).fromNow()}</Text>;
      },
    },
    {
      key: "actions",
      title: "Actions",
      search: false,
      width: 100,
      render: (_, record) => [
        <div key={record.id} className="actions-wrapper">
          <Button
            className="icon-button"
            icon={<FileTextFilled />}
            onClick={() => navigate(`/events/${record?.id}`)}
          />

          <Button
            className="icon-button"
            icon={<EditFilled />}
            onClick={() => navigate(`/events/${record?.id}/edit`)}
          />

          <Button
            className="icon-button"
            icon={<DeleteFilled />}
            onClick={() => showModal(record)}
          />
        </div>,
      ],
    },
  ];
};
