import {
  ProForm,
  ProFormText,
  PageContainer,
  ProFormDateTimePicker,
  ProFormSwitch,
  ProFormSelect,
  ProFormDigit,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import ImageUpload from "../../../components/UploadImage";
import { useCallback, useEffect, useState } from "react";
import {
  getClubs,
  getEventData,
  getFoodCourtShippingZone,
  getPlaces,
  getSubCategories,
  putEvent,
} from "../../../api";
import {
  CustomizeCountries,
  CustomizeOptionsByApi,
  CustomizeOptionsByConstant,
  CustomizeOptionsByShippingZoneApi,
} from "../../../utils/customizeOptions";
import { useForm } from "antd/lib/form/Form";
import {
  CONCERT,
  EVENT_STATUSES,
  EVENT_TYPES,
  SPORT,
  FOOD_COURT,
} from "../../../utils/constant";
import SportType from "../SportType";
import { useParams } from "react-router-dom";
import ConcertType from "../ConcertType";
import * as dayjs from "dayjs";
import FoodCourtType from "../FoodCourtType";

const EditEvent = () => {
  const { id } = useParams();
  const [form] = useForm();
  const navigate = useNavigate();

  const [images, setImages] = useState({
    titleImage: null,
    ticketTypeImage: null,
    eventDetailImage: null,
  });
  const [clubs, setClubs] = useState([]);
  const [sportSubCategories, setSportSubCategories] = useState([]);
  const [concertSubCategories, setConcertSubCategories] = useState([]);
  const [foodCourtSubCategories, setFoodCourtSubCategories] = useState([]);
  const [eventType, setEventType] = useState();
  const [eventData, setEventData] = useState();
  const [countries, setCountries] = useState([]);
  const [shippingZoneList, setShippingZoneList] = useState([]);

  const fetchFoodCourtShippingZone = useCallback(async () => {
    try {
      const response = await getFoodCourtShippingZone();
      return setShippingZoneList(
        CustomizeOptionsByShippingZoneApi(response.data)
      );
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  const fetchPlaces = useCallback(async () => {
    try {
      const response = await getPlaces();

      setCountries(CustomizeCountries(response));
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  const fetchClubs = useCallback(async () => {
    try {
      const response = await getClubs();
      const customizeData = CustomizeOptionsByApi(response);

      setClubs(customizeData);
    } catch (error) {
      console.log("Error", error);
      console.log("Error", error);
    }
  }, []);

  const fetchEventData = useCallback(async () => {
    try {
      const response = await getEventData(id);

      setEventData(response);
      setEventType(response.category);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const fetchSubCategories = useCallback(async () => {
    try {
      const response = await getSubCategories({ select: true });

      response.forEach(
        (item) =>
          (item.category === SPORT &&
            setSportSubCategories(CustomizeOptionsByApi(item.subCategories))) ||
          (item.category === CONCERT &&
            setConcertSubCategories(
              CustomizeOptionsByApi(item.subCategories)
            )) ||
          (item.category === FOOD_COURT &&
            setFoodCourtSubCategories(
              CustomizeOptionsByApi(item.subCategories)
            ))
      );
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  const handleSubmit = async (values) => {
    console.log("🚀 ~ file: index.js:89 ~ handleSubmit ~ values:", values);
    try {
      const newParams = {
        ...values,
        image: images?.titleImage ?? eventData.image,
        ticketTypeImage: images?.ticketTypeImage ?? eventData.ticketTypeImage,
        eventDetailImage:
          images?.eventDetailImage ?? eventData.eventDetailImage,
        startTime: values.startTime
          ? dayjs
              .tz(values.startTime, "YYYY-MM-DD HH:mm", "Asia/Riyadh")
              .utc()
              .format()
          : null,
        // endTime: values.endTime
        //   ? dayjs
        //       .tz(values.endTime, "YYYY-MM-DD HH:mm", "Asia/Riyadh")
        //       .utc()
        //       .format()
        //   : null,
        openForTicket: values.openForTicket
          ? dayjs
              .tz(values.openForTicket, "YYYY-MM-DD HH:mm", "Asia/Riyadh")
              .utc()
              .format()
          : null,
      };

      const response = await putEvent(id, newParams);

      if (response.status === 200) {
        navigate(`/events/${id}`);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleSetImage = (value, key, callBack) => {
    form.setFieldValue(key, value);

    callBack((prevState) => ({ ...prevState, [key]: value.url }));
    form.validateFields([key]);
  };

  useEffect(() => {
    fetchSubCategories();
    fetchClubs();
    fetchEventData();
    fetchPlaces();
  }, [
    fetchClubs,
    fetchEventData,
    fetchPlaces,
    fetchSubCategories,
    fetchFoodCourtShippingZone,
  ]);

  return (
    <PageContainer title="Edit event">
      {eventData && (
        <ProForm
          form={form}
          name="editEventForm"
          layout="horizontal"
          onFinish={handleSubmit}
          className="create-event-form event-form"
          initialValues={{
            ...eventData,
            startTime: dayjs
              .utc(eventData?.startTime)
              .utcOffset(3)
              .format("YYYY/MM/DD HH:mm"),
            // endTime: matchData.endTime
            //   ? dayjs
            //       .utc(matchData?.endTime)
            //       .utcOffset(3)
            //       .format("YYYY/MM/DD HH:mm")
            //   : null,
            openForTicket: dayjs
              .utc(eventData?.openForTicket)
              .utcOffset(3)
              .format("YYYY/MM/DD HH:mm"),
          }}
          onFinishFailed={(values) => {
            console.log("🚀 ~ values:", values);
          }}
          scrollToFirstError
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormText
                name="nameEn"
                label="English Name"
                width={"lg"}
                placeholder="Enter event name in English"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        English name is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormText
                name="nameAr"
                width={"lg"}
                label="Arabic Name"
                placeholder="Enter event name in Arabic"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Arabic name is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormText
                name="locationEn"
                width={"lg"}
                label="English Location"
                placeholder="Enter location"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        English location is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormText
                name="locationAr"
                width={"lg"}
                label="Arabic Location"
                placeholder="Enter location"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Arabic location is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormText
                name="cityEn"
                width={"lg"}
                label="English City"
                placeholder="Enter city"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        English city is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormText
                name="cityAr"
                width={"lg"}
                label="Arabic City"
                placeholder="Enter city"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Arabic city is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              {eventType === FOOD_COURT && (
                <ProFormSelect
                  name="eventZoneId"
                  width={"lg"}
                  label="Shipping Zone"
                  placeholder="Select shipping zone"
                  rules={[
                    {
                      required: true,
                      message: () => (
                        <p className="error-message">
                          Shipping zone is required! My friends
                        </p>
                      ),
                    },
                  ]}
                  options={shippingZoneList}
                  showSearch
                />
              )}

              <ProFormSelect
                label="Country"
                name="country"
                width={"lg"}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Country is required! My friends
                      </p>
                    ),
                  },
                ]}
                placeholder="Select country"
                options={countries}
                showSearch
              />

              <ProFormDateTimePicker
                name="startTime"
                tooltip="This always set to Saudi Arabia Timezone"
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                width={"lg"}
                label="Start time"
                placeholder="Choose end time"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Start time is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormDateTimePicker
                name="openForTicket"
                tooltip="This always set to Saudi Arabia Timezone"
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                width={"lg"}
                label="Open for ticket"
                placeholder="Choose time"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Open for ticket is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormSelect
                name="status"
                width={"lg"}
                label="Status"
                placeholder="Select status"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Status is required! My friends
                      </p>
                    ),
                  },
                ]}
                options={EVENT_STATUSES}
              />

              <ProFormSelect
                name="category"
                width={"lg"}
                label="Event type"
                placeholder="Select event type"
                options={CustomizeOptionsByConstant(EVENT_TYPES)}
                onChange={(value) => {
                  console.log("value", value);
                  setEventType(value);
                  console.log("eventType", eventType);
                }}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Event type is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              {(eventType || eventData.category) && (
                <>
                  <ProFormSelect
                    name="subCategoryId"
                    width={"lg"}
                    label="Sub category"
                    placeholder="Select sub category"
                    rules={[
                      {
                        required: true,
                        message: () => (
                          <p className="error-message">
                            Sub category is required! My friends
                          </p>
                        ),
                      },
                    ]}
                    options={
                      eventType === SPORT
                        ? sportSubCategories
                        : eventType === CONCERT
                        ? concertSubCategories
                        : eventType === FOOD_COURT
                        ? foodCourtSubCategories
                        : []
                    }
                  />
                  {eventType === SPORT ? (
                    <SportType
                      clubs={clubs}
                      formData={form}
                      eventData={eventData}
                    />
                  ) : eventType === CONCERT ? (
                    <ConcertType formData={form} eventData={eventData} />
                  ) : eventType === FOOD_COURT ? (
                    <FoodCourtType formData={form} eventData={eventData} />
                  ) : null}
                </>
              )}
            </div>
          </ProForm.Group>

          <ProForm.Item
            label="Event detail image:"
            name="eventDetailImage"
            rules={[
              {
                required: true,
                message: () => (
                  <p className="error-message">
                    Event image is required! My friends
                  </p>
                ),
              },
            ]}
          >
            <ImageUpload
              initialValue={eventData?.eventDetailImage}
              callBackGetUrl={(value) =>
                handleSetImage(value, "eventDetailImage", setImages)
              }
            />
          </ProForm.Item>

          <ProForm.Item
            label="Title image:"
            name="image"
            rules={[
              {
                required: true,
                message: () => (
                  <p className="error-message">
                    Title image is required! My friends
                  </p>
                ),
              },
            ]}
          >
            <ImageUpload
              initialValue={eventData?.image}
              callBackGetUrl={(value) =>
                handleSetImage(value, "titleImage", setImages)
              }
            />
          </ProForm.Item>

          <ProForm.Item
            label="Ticket type image:"
            name="ticketTypeImage"
            rules={[
              {
                required: true,
                message: () => (
                  <p className="error-message">
                    Ticket type image is required! My friends
                  </p>
                ),
              },
            ]}
          >
            <ImageUpload
              initialValue={eventData?.ticketTypeImage}
              callBackGetUrl={(value) =>
                handleSetImage(value, "ticketTypeImage", setImages)
              }
            />
          </ProForm.Item>

          <ProForm.Item>
            <ProFormSwitch name="isFeatured" label="Is featured?" />
          </ProForm.Item>
          <ProForm.Item>
            <ProFormSwitch name="isDraft" label="Is Draft?" />
          </ProForm.Item>

          <ProForm.Item>
            <ProFormDigit
              width={"sm"}
              name={["finalData", "sold"]}
              label="Number of ticket sold"
            />
          </ProForm.Item>

          <ProForm.Item>
            <ProFormDigit
              width={"sm"}
              name={["finalData", "checkedIn"]}
              label="Number of ticket checkin"
            />
          </ProForm.Item>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default EditEvent;
