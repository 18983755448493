import axios from "axios";
import api from "../utils/axios-client";
import { message } from "antd";

export const requestOTP = async (values) => {
  try {
    const response = await api.post("operator/sms-otps", {
      recipients: values?.phoneNumber,
      countryCode: values?.countryCode,
    });

    return response.data;
  } catch (error) {
    const {
      response: {
        data: { message: text },
      },
    } = error;

    message.error(text);
    console.log("Error:", error);
  }
};

export const verifyOTP = async (phone, otpCode, countryCode) => {
  try {
    const response = await api.post("/sms-otps/verify", {
      recipients: phone,
      code: Number(otpCode),
      countryCode: countryCode,
    });

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const getProfile = async (token) => {
  try {
    const response = await api.get("client/users/profile", {
      headers: { authorization: `bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.log("error");
  }
};

// Clubs
export const getClubs = async (filterParams, currentPage, pageSize) => {
  try {
    const response = await api.get("operator/clubs", {
      // headers: {
      //   "x-token-auth": "U2FsdGVkX18oekpRDKTJdYxwqOF2b8ceB2F4Fm1Zdxw=",
      // },
      params: {
        ...filterParams,
        page: currentPage,
        limit: pageSize,
      },
    });

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const postClub = async (params) => {
  try {
    const response = await api.post("operator/clubs", params);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const patchClub = async (recordId, params) => {
  try {
    const response = await api.patch(`operator/clubs/${recordId}`, params);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteClub = async (recordId) => {
  try {
    const response = await api.delete(`operator/clubs/${recordId}`);

    return response;
  } catch (error) {
    console.log("error");
  }
};

export const getClubData = async (recordId) => {
  try {
    const response = await api.get(`operator/clubs/${recordId}`);

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

// events
export const getEvents = async (params) => {
  try {
    const response = await api.get(`operator/events`, {
      params: {
        ...params,
      },
    });

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const getEventData = async (recordId) => {
  try {
    const response = await api.get(`operator/events/${recordId}`);

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const postEvent = async (params) => {
  try {
    const response = await api.post("operator/events", params);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const putEvent = async (recordId, params) => {
  try {
    const response = await api.put(`operator/events/${recordId}`, params);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteEvent = async (recordId) => {
  try {
    const response = await api.delete(`operator/events/${recordId}`);

    return response;
  } catch (error) {
    console.log("error");
  }
};

//sub category
export const getSubCategories = async (
  params,
  currentPage = null,
  pageSize = null
) => {
  try {
    const response = await api.get("operator/sub-categories", {
      params: {
        page: currentPage,
        limit: pageSize,
        ...params,
      },
    });

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const getSubCategory = async (recordId) => {
  try {
    const response = await api.get(`operator/sub-categories/${recordId}`);

    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const postSubCategories = async (params) => {
  try {
    const response = await api.post("operator/sub-categories", params);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const patchSubCategories = async (recordId, params) => {
  try {
    const response = await api.patch(
      `operator/sub-categories/${recordId}`,
      params
    );

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteSubCategory = async (recordId) => {
  try {
    const response = await api.delete(`operator/sub-categories/${recordId}`);

    return response;
  } catch (error) {
    console.log("error");
  }
};

// tickets
export const getTickets = async (
  recordId,
  filterParams,
  currentPage,
  pageSize
) => {
  try {
    const url =
      currentPage && pageSize
        ? `operator/tickets?&page=${currentPage}&limit=${pageSize}`
        : "operator/tickets";

    const response = await api.get(url, {
      params: {
        eventId: recordId,
        ...filterParams,
      },
    });

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const getTicketData = async (recordId) => {
  try {
    const response = await api.get(`operator/tickets/${recordId}`);

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const patchEventTicket = async (recordId, data) => {
  try {
    const response = await api.patch(`operator/tickets/${recordId}`, data);

    return response;
  } catch (error) {
    throw error;
  }
};

// ticket types

export const getTicketTypesByEventId = async (recordId) => {
  try {
    const response = await api.get(`operator/events/${recordId}/ticket-types`);

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const getTicketTypeData = async (ticketId) => {
  try {
    const response = await api.get(`operator/ticket-types/${ticketId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTicketTypeClubs = async (id) => {
  try {
    const response = await api.get(`operator/events/${id}/clubs`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postTicketType = async (data) => {
  try {
    const response = await api.post(`operator/ticket-types/`, data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const patchTicketType = async (ticketId, data) => {
  try {
    const response = await api.patch(`operator/ticket-types/${ticketId}`, data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteTicketType = async (ticketId) => {
  try {
    const response = await api.delete(`operator/ticket-types/${ticketId}`);

    return response;
  } catch (error) {
    throw error;
  }
};

// check in histories
export const getCheckInHistories = async (
  recordId,
  filterParams,
  page,
  limit
) => {
  try {
    const response = await api.get("operator/checkin-histories", {
      params: {
        ...filterParams,
        eventId: recordId,
        page,
        limit,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Users
export const getEventUsers = async (recordId, filterParams, page, limit) => {
  try {
    const response = await api.get(
      `/operator/events/${recordId}/users/select`,
      {
        params: {
          ...filterParams,
          page,
          limit,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const postUserPermission = async (params) => {
  try {
    const response = await api.post("operator/user-permissions", params);

    return response;
  } catch (error) {
    console.log("Error", error);
  }
};

export const deleteUserPermission = async (params) => {
  try {
    const response = await api.delete("operator/user-permissions", {
      data: {
        ...params,
      },
    });

    return response;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getEventOrders = async (recordId, filterParams, page, limit) => {
  try {
    const response = await api.get("operator/orders", {
      params: {
        ...filterParams,
        eventId: recordId,
        limit,
        page,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getEventOrderData = async (recordId) => {
  try {
    const response = await api.get(`operator/orders/${recordId}`);

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const patchEventOrder = async (recordId, data) => {
  try {
    const response = await api.patch(`operator/orders/${recordId}`, data);

    return response;
  } catch (error) {
    console.log("Error", error);
  }
};

export const postEventSponsors = async (params) => {
  try {
    const response = await api.post(
      `operator/events/${params.eventId}/sponsors`,
      params
    );

    return response;
  } catch (error) {
    console.log("Error", error);
  }
};

export const deleteEventSponsors = async (params) => {
  try {
    const response = await api.delete(
      `operator/events/${params.eventId}/sponsors`,
      { data: { ...params } }
    );

    return response;
  } catch (error) {
    console.log("Error", error);
  }
};

// Sliders
export const getSliders = async (currentPage, pageSize) => {
  try {
    const response = await api.get("operator/sliders", {
      params: {
        limit: pageSize,
        page: currentPage,
      },
    });

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const getSliderData = async (ticketId) => {
  try {
    const response = await api.get(`operator/sliders/${ticketId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postSlider = async (params) => {
  try {
    const response = await api.post("operator/sliders", params);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const patchSlider = async (recordId, data) => {
  try {
    const response = await api.patch(`operator/sliders/${recordId}`, data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteSlider = async (recordId) => {
  try {
    const response = await api.delete(`operator/sliders/${recordId}`);

    return response;
  } catch (error) {
    console.log("Error", error);
  }
};

// Places
export const getPlaces = async () => {
  try {
    const response = await api.get(`/places`);

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

// Settings
export const getSettings = async () => {
  try {
    const response = await api.get(`operator/settings`);

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const patchSettings = async (params) => {
  try {
    const response = await api.patch(`operator/settings`, [...params]);

    return response;
  } catch (error) {
    console.log("Error", error);
  }
};

// Odoo waiting
export const getOdooWaiting = async () => {
  try {
    const response = await api.get(`operator/orders/odoo/waiting`);

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const postSyncAllToOdoo = async () => {
  try {
    const response = await api.post(`operator/orders/odoo/sync-all`);

    return response;
  } catch (error) {
    console.log("Error", error);
  }
};

// Users
export const getUsers = async (filterParams, page, limit) => {
  try {
    const response = await api.get("operator/users", {
      params: {
        ...filterParams,
        page,
        limit,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const postUser = async (params) => {
  try {
    const response = await api.post("operator/users", params);

    return response;
  } catch (error) {
    console.log("Error", error);
  }
};

export const patchUser = async (recordId, params) => {
  try {
    const response = await api.patch(`operator/users/${recordId}`, params);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteUser = async (recordId) => {
  try {
    const response = await api.delete(`operator/users/${recordId}`);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const getUserData = async (recordId) => {
  try {
    const response = await api.get(`operator/users/${recordId}`);

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const exportUsers = async () => {
  try {
    return await api.post(
      `operator/users/excel`,
      {},
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    console.log("Error", error);
  }
};

// Upload image
export const uploadImageAPI = async (file) => {
  try {
    const response = await api.post("storages/upload-image", file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

// OTPs
export const getOTPs = async (filterParams, page, limit) => {
  try {
    const response = await api.get(`operator/sms-otps`, {
      params: {
        ...filterParams,
        page,
        limit,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

// Queue
export const getQueue = async (recordId) => {
  try {
    const response = await api.get(`operator/events/${recordId}/queue`);

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const postQueue = async (recordId, params) => {
  try {
    const response = await api.post(
      `operator/events/${recordId}/queue`,
      params
    );

    return response;
  } catch (error) {
    console.log("Error", error);
  }
};

export const putQueue = async (recordId, params) => {
  try {
    const response = await api.put(`operator/events/${recordId}/queue`, params);

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const deleteQueue = async (recordId) => {
  try {
    const response = await api.delete(`operator/events/${recordId}/queue`);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

// payment logs
export const getPaymentLogs = async (filterParams, page, limit) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_PAYMENT_URL}/logs-status-payment`,
      {
        params: {
          filters: {
            ...filterParams,
          },
          page,
          limit,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

// order logs
export const getOrderLogs = async (filterParams, currentPage, pageSize) => {
  try {
    const response = await api.get(`operator/log-orders`, {
      params: {
        ...filterParams,
        page: currentPage,
        limit: pageSize,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getOrderLogData = async (recordId) => {
  try {
    const response = await api.get(`operator/log-orders/${recordId}`);

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getCoupons = async (params) => {
  try {
    const response = await api.get(`operator/coupons`, {
      params: {
        ...params,
      },
    });

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const postCoupon = async (params) => {
  try {
    const response = await api.post(`operator/coupons`, params);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteCoupon = async (recordId) => {
  try {
    const response = await api.delete(`operator/coupons/${recordId}`);

    return response;
  } catch (error) {
    console.log("error");
  }
};

export const getDetailCoupon = async (recordId) => {
  try {
    const response = await api.get(`operator/coupons/${recordId}`);

    return response;
  } catch (error) {
    console.log("error");
  }
};

export const getTicketTypeByCoupon = async (couponId) => {
  try {
    const response = await api.get(
      `operator/coupons/${couponId}/ticket-types/select`
    );

    return response;
  } catch (error) {
    console.log("error");
  }
};

export const editCoupon = async (recordId, params) => {
  try {
    const response = await api.patch(`operator/coupons/${recordId}`, params);

    return response;
  } catch (error) {
    console.log("error");
  }
};

export const getCountTickets = async (couponId) => {
  try {
    const response = await api.get(
      `operator/coupons/${couponId}/count-tickets`
    );

    return response;
  } catch (error) {
    console.log("error");
  }
};

export const getListTicketType = async (params) => {
  try {
    const response = await api.get(`operator/ticket-types`, { params });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAssignTicketTypeForCoupon = async (couponId, params) => {
  try {
    const response = await api.post(
      `operator/coupons/${couponId}/ticket-types/assign`,
      { ...params }
    );

    return response;
  } catch (error) {
    console.log("error");
  }
};

export const getListTicketTypeSelect = async (params) => {
  try {
    const response = await api.get(`operator/coupons/ticket-types/select`, {
      params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTransactionStatus = async (transactionId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PAYMENT_CLIENT_URL}/payment/transaction/${transactionId}`,
      {
        headers: { "x-token-auth": process.env.REACT_APP_PAYMENT_X_TOKEN_AUTH },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFoodCourtShippingZone = async (
  limit,
  page = null,
  name = null
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SHIPPING_ZONE_URL}/api/v2/operator/event-shipping-zone`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
