import React, { useCallback, useEffect, useState } from "react";
import {
  PageContainer,
  ProForm,
  ProFormDigit,
  ProFormMoney,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
} from "@ant-design/pro-components";
import { notification } from "antd";
import { useNavigate, useParams } from "react-router";
import { getTicketTypeClubs, postTicketType } from "../../../../api";
import { TICKET_COLORS } from "../../../../utils/constant";
import { CustomizeOptionsByName } from "../../../../utils/customizeOptions";
import ImageUpload from "../../../../components/UploadImage";

const NewTicketType = () => {
  const [form] = ProForm.useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  const [clubs, setClubs] = useState([]);
  const [images, setImages] = useState({ ticketTypeImage: null });

  const handleSetImage = (value, key, callBack) => {
    form.setFieldValue(key, value);

    callBack((prevState) => ({ ...prevState, [key]: value.url }));
    form.validateFields([key]);
  };

  const handleRemoveImage = (key) => {
    form.setFieldValue(key, null);
  };

  const fetchTicketTypeClubs = useCallback(async () => {
    try {
      const response = await getTicketTypeClubs(id);

      setClubs(CustomizeOptionsByName(response));
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        // const { ticketColor, ...rest } = values;
        const newParams = {
          ...values,
          eventId: id,
          ticketTypeImage: images.ticketTypeImage,
        };
        const response = await postTicketType(newParams);

        if (response.status === 201) {
          navigate(`/events/${id}`);
          notification.success({
            message: "Create ticket type successfully! My friends",
          });
        }
      } catch (error) {
        console.log("Error", error);
        notification.error({
          message: "Something went wrong!",
        });
      }
    },
    [id, navigate, images.ticketTypeImage]
  );

  useEffect(() => {
    fetchTicketTypeClubs();
  }, [fetchTicketTypeClubs]);

  return (
    <PageContainer title="New Ticket Type">
      <ProForm
        name="createTicketType"
        layout="horizontal"
        form={form}
        onFinish={handleSubmit}
        className="create-event-form event-form"
      >
        <ProForm.Group>
          <div className="form-fields-wrapper">
            <ProFormText
              name="nameEn"
              label="Name in English"
              width={"md"}
              rules={[{ required: true }]}
            />

            <ProFormText
              name="nameAr"
              label="Name in Arabic"
              width={"md"}
              rules={[{ required: true }]}
            />

            {clubs.length > 0 && (
              <ProFormSelect
                label="Club"
                name="clubId"
                width={"md"}
                placeholder="Select club"
                options={clubs}
                showSearch
              />
            )}

            <ProFormMoney
              name="price"
              label="Price"
              width={"md"}
              localeCustomSymbol={"SA"}
              min={0}
              customSymbol={"SAR"}
              rules={[{ required: true }]}
              fieldProps={{ controls: false }}
            />

            <ProFormDigit
              name="limitForUser"
              label="Limit buying tickets"
              width={"md"}
              min={0}
              rules={[{ required: true }]}
              fieldProps={{ controls: false }}
            />

            <ProFormDigit
              name="totalQuantity"
              label="Total quantity"
              placeholder="Enter total quantity"
              width={"md"}
              min={1}
              rules={[{ required: true }]}
              fieldProps={{ controls: false }}
            />

            <ProFormSelect
              label="Color"
              name="ticketColor"
              width={"md"}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Color is required! My friends
                    </p>
                  ),
                },
              ]}
              placeholder="Select ticket color"
              options={TICKET_COLORS}
              fieldProps={{
                labelInValue: true,
                optionItemRender: (item) => {
                  return (
                    <div
                      style={{
                        backgroundColor: item.backgroundColor,
                        color: item.textColor,
                        padding: "0px 8px",
                        borderRadius: 4,
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                        }}
                      >
                        {item.label}
                      </p>
                    </div>
                  );
                },
              }}
              showSearch
            />
            <ProFormSwitch name="isBlock" label="Is block?" />
          </div>
        </ProForm.Group>
        <ProForm.Group>
          <ProForm.Item label="Ticket image (Optional):" name="ticketTypeImage">
            <ImageUpload
              callBackGetUrl={(value) =>
                handleSetImage(value, "ticketTypeImage", setImages)
              }
              deleteable={true}
              removeImageFnc={handleRemoveImage("ticketTypeImage")}
            />
          </ProForm.Item>
        </ProForm.Group>
      </ProForm>
    </PageContainer>
  );
};

export default NewTicketType;
